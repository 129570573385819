import Utils from "libs/Utils";

import Cookies from "js-cookie";
import mediator from "libs/mediator";

(function() {
  let cookie = null;
  let cookieBar = document.querySelector('.cookies-eu');

  let closeBtn, stickyBar;

  if (cookieBar) {
    closeBtn = cookieBar.querySelector(".cookies-eu__close");
    stickyBar = document.querySelector(".sticky-bar");
  }

  try {
    cookie = Cookies.get('cookie_eu_consented');
  } catch (err) {
    cookie = null;
  }

  show();

  if (stickyBar) {
    stickyBar.addEventListener("transitionend", function() {
      mediator.trigger("banner:show");
    });
  }

  if (cookieBar.style.display !== 'none') {
    closeBtn.addEventListener('click', function(ev) {
      ev.preventDefault();

      Cookies.set('cookie_eu_consented', 'true', {path: '/', expires: 365, secure: true});
      close();
    });
  }

  function show() {
    if (cookie !== 'true') {
      cookieBar.style.display = '';
    }

    setTimeout(function() {
      let bannerShow = new CustomEvent("banner:show");
      document.body.dispatchEvent(bannerShow);
    }, 500);
  }

  function close() {
    let closeCookie = new CustomEvent("cookies_eu:close");
    document.body.dispatchEvent(closeCookie);
    cookieBar.style.display = 'none';

    let bannerHide = new CustomEvent("banner:show");
    document.body.dispatchEvent(bannerHide);
  }

  Utils.log("CookiesEU module");
})();

(function() {
  let cookieMessage = null;
  let cookieBarMessage = document.querySelector('.cookies-message');

  let closeBtnMessage;

  if (cookieBarMessage) {
    closeBtnMessage = cookieBarMessage.querySelector(".cookies-message__btn");
  } else {
    return
  }

  try {
    cookieMessage = Cookies.get('cookie_eu_consented');
  } catch (err) {
    cookieMessage = null;
  }

  show();

  if (cookieBarMessage.style.display !== 'none') {
    closeBtnMessage.addEventListener('click', function(ev) {
      ev.preventDefault();

      Cookies.set('cookie_eu_consented', 'true', {path: '/', expires: 365, secure: true});
      close();
    });
  }

  function show() {
    if (cookieMessage !== 'true') {
      cookieBarMessage.style.display = '';
    }

    setTimeout(function() {
      let bannerShow = new CustomEvent("banner:show");
      document.body.dispatchEvent(bannerShow);
    }, 500);
  }

  function close() {
    let closeCookie = new CustomEvent("cookies_message:close");
    document.body.dispatchEvent(closeCookie);
    cookieBarMessage.style.display = 'none';

    let bannerHide = new CustomEvent("banner:show");
    document.body.dispatchEvent(bannerHide);
  }

  Utils.log("CookiesEL49 module");
})()
